/* eslint-disable no-undef */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hamburger', 'mobileMenu', 'link'];

  connect() {
    this.linkTargets.forEach((link) => {
      link.classList.remove('text-yellow-5', 'border-b', 'border-yellow-5');
      const currentPage = link.dataset.page;
      const currentPathname = document.location.pathname;

      if (currentPathname === currentPage) {
        link.classList.add('text-yellow-5', 'border-b', 'border-yellow-5');
      }
    });
  }

  toggle() {
    this.hamburgerTarget.classList.toggle('active');
    this.mobileMenuTarget.classList.toggle('active');
    this.element.classList.toggle('overflow-hidden');
  }
}
