/* eslint-disable no-undef */

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

import SmartListing from 'smart_listing';

window.Stimulus = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);

Stimulus.load(definitionsFromContext(context));

Stimulus.register('smart-listing', SmartListing.controllers.main.tailwind);
Stimulus.register('smart-listing-controls', SmartListing.controllers.controls.tailwind);
