import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'sliderMin',
    'sliderMax',
    'minValuePlaceholder',
    'maxValuePlaceholder',
    'sliderTrack',
  ];

  minGap = 0;

  minValue = this.sliderMinTarget.min;

  maxValue = this.sliderMinTarget.max;

  connect() {
    this.handleSliderMin();
    this.handleSliderMax();
  }

  handleSliderMin() {
    if (
      parseInt(this.sliderMaxTarget.value, 10) -
        parseInt(this.sliderMinTarget.value, 10) <=
      this.minGap
    ) {
      this.sliderMinTarget.value =
        parseInt(this.sliderMaxTarget.value, 10) - this.minGap;
    }
    this.minValuePlaceholderTarget.textContent = this.sliderMinTarget.value;
    this.fillColor();
  }

  handleSliderMax() {
    if (
      parseInt(this.sliderMaxTarget.value, 10) -
        parseInt(this.sliderMinTarget.value, 10) <=
      this.minGap
    ) {
      this.sliderMaxTarget.value =
        parseInt(this.sliderMinTarget.value, 10) + this.minGap;
    }
    this.maxValuePlaceholderTarget.textContent = this.sliderMaxTarget.value;
    this.fillColor();
  }

  fillColor() {
    const percent1 =
      ((this.sliderMinTarget.value - this.minValue) /
        (this.maxValue - this.minValue)) *
      100;

    const percent2 =
      ((this.sliderMaxTarget.value - this.minValue) /
        (this.maxValue - this.minValue)) *
      100;

    this.sliderTrackTarget.style.background = `linear-gradient(to right, #C4C4C4 ${percent1}%, #F7C819 ${percent1}% ${percent2}%, #C4C4C4 ${percent2}%)`;
  }

  onReset() {
    this.sliderMinTarget.value = this.minValue;
    this.sliderMaxTarget.value = this.maxValue;

    this.minValuePlaceholderTarget.textContent = this.sliderMinTarget.value;
    this.maxValuePlaceholderTarget.textContent = this.sliderMaxTarget.value;

    this.sliderTrackTarget.style.background = '#F7C819';
  }
}
