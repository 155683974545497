import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filterBox'];

  openModal() {
    this.filterBoxTarget.classList.remove('hidden');
  }

  closeModal() {
    this.filterBoxTarget.classList.add('hidden');
  }
}
