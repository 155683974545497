import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [];

  handleRedirect = (event) => {
    const redirectUrl = event.detail.fetchResponse.response.headers.get('X-Splash-RedirectTo');

    if (redirectUrl) {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        window.location.href = redirectUrl;
      }, 3000);
    }
  }
}
