/* eslint no-param-reassign: ["error", { "props": false }] */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'sliderResetButton',
    'region',
    'flag',
    'language',
    'languageLabel',
    'subject',
    'subjectLabel',
  ];

  checkCheckboxesStatus = (checkboxes, labels, dataAttribute) => {
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        labels.forEach((label) => {
          if (
            checkbox.dataset[dataAttribute] === label.dataset[dataAttribute]
          ) {
            label.classList.add('practices__checkbox--active');
          }
        });
      }
    });
  };

  toggleCheckboxClasses = (event, element) => {
    if (event.target.checked) {
      element.classList.add('practices__checkbox--active');
    } else {
      element.classList.remove('practices__checkbox--active');
    }
  };

  resetCheckboxes = (checkboxes, labels) => {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    labels.forEach((label) => {
      label.classList.remove('practices__checkbox--active');
    });
  };

  connect() {
    this.checkCheckboxesStatus(this.regionTargets, this.flagTargets, 'region');
    this.checkCheckboxesStatus(
      this.languageTargets,
      this.languageLabelTargets,
      'language'
    );
    this.checkCheckboxesStatus(
      this.subjectTargets,
      this.subjectLabelTargets,
      'subject'
    );
  }

  onResetFilters() {
    this.sliderResetButtonTargets.forEach((button) => button.click());

    this.resetCheckboxes(this.regionTargets, this.flagTargets);
    this.resetCheckboxes(this.languageTargets, this.languageLabelTargets);
    this.resetCheckboxes(this.subjectTargets, this.subjectLabelTargets);
  }

  onChangeRegion = (e) => {
    const flagImg = e.target.labels[0].firstElementChild;
    this.toggleCheckboxClasses(e, flagImg);
  };

  onChangeLanguage = (e) => {
    const label = e.target.labels[0];
    this.toggleCheckboxClasses(e, label);
  };

  onChangeSubject = (e) => {
    const label = e.target.labels[0];
    this.toggleCheckboxClasses(e, label);
  };
}
